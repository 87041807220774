import axios from "axios";
import Cookies from 'js-cookie'
export const isDev = true
const token = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9pc3RvY2stbWFuYWdlLXBocDo1MjAwXC9hcGlcL3Y0XC9jb21tZW50c1wvbGlzdCIsImlhdCI6MTY0NzIyMzMzMSwiZXhwIjoxNjgzNTExMzMxLCJuYmYiOjE2NDcyMjMzMzEsImp0aSI6IkF1eFZDaEdKQkd4NVZ0aDAiLCJzdWIiOjEsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjcifQ.pLwrAxPlznUWz778ES1JEd3OkFHGC6w65O9wc8QoP9E'
export function requestDataTGPMethod(actionType, method, param, resultMethod, haveLoad) {
    let u = navigator.userAgent
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    var uuid = generateUUID();
    if (isDev == true) {
        Cookies.set('token', token);
        axios.request({
            url: '/api' + '/' + method,
            headers: {
                Authorization: token,
                device: '123'
            },
            params: param,
            method: 'post'
        }).then(res => {
            let str = 'window.' + resultMethod + '(res.data)'
            eval(str);
        }).catch(function(error) {
            //console.log(error.log)
        })
    } else {
        if (isIOS) {
            window.webkit.messageHandlers.jsMessageHandler.postMessage(JSON.stringify({ 'actionType': actionType, 'param': param, 'resultMessage': resultMethod, 'method': method, 'haveLoad': haveLoad, 'requestUuid': uuid }));
        } else {
            JSBridge.showMessageInNative(JSON.stringify({ 'actionType': actionType, 'param': param, 'resultMessage': resultMethod, 'method': method, 'haveLoad': haveLoad, 'requestUuid': uuid }))
        }
    }
}
//原生QUERY数据请求
export function requestQueryDataTGPMethod(method, param, resultMethod, haveLoad, requestType) {
    let u = navigator.userAgent
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    var uuid = generateUUID();
    if (isDev == true) {
        Cookies.set('token', token);
        axios.request({
            url: '/api' + '/' + method,
            headers: {
                Authorization: token,
            },
            params: param,
            method: requestType
        }).then(res => {
            let str = 'window.' + resultMethod + '(res.data)'
            eval(str);
        }).catch(function(error) {
            //console.log(error.log)
        })
    } else {
        if (isIOS) {
            window.webkit.messageHandlers.jsMessageHandler.postMessage(JSON.stringify({ 'actionType': 'requestQueryDataTGPMethod', 'param': param, 'resultMessage': resultMethod, 'method': method, 'haveLoad': haveLoad, 'requestType': requestType, 'requestUuid': uuid }));
        } else {
            JSBridge.showMessageInNative(JSON.stringify({ 'actionType': 'requestQueryDataTGPMethod', 'param': param, 'resultMessage': resultMethod, 'method': method, 'haveLoad': haveLoad, 'requestType': requestType, 'requestUuid': uuid }))
        }
    }
}
//关注
export function attendSomeTeacher(teacherId, resultMethod) {
    requestDataTGPMethod('ccAttend', 'ccAttend', { 'id': teacherId }, resultMethod, '0')
}
// 取消关注
export function unAttendSomeTeacher(teacherId, resultMethod) {
    requestDataTGPMethod('ccUnAttend', 'ccUnAttend', { 'id': teacherId }, resultMethod, '0')
}
//磁盘缓存数据
export function getLocalData(key) {
    //console.log('getLocalData');
    requestDataTGPMethod('getLocalData', key, {}, 'getLocalData', '0')
}
//存储临时缓存数据
export function saveCacheData(key, dataDic, method) {
    requestDataTGPMethod('saveCacheData', key, dataDic, method, '0')
}
//删除临时缓存数据
export function deleteCacheData(key, method) {
    requestDataTGPMethod('deleteCacheData', key, {}, method, '0')
}
//读取临时缓存数据
export function getCacheData(key, method) {
    requestDataTGPMethod('getCacheData', key, {}, method, '0')
}
//token
export function updateTokenFromNative() {
    requestDataTGPMethod('getLocalData', 'token', {}, 'updateTokenFromNative', '0')
}
//是否登录
export function ifLogin() {
    var token = Cookies.get("token");
    if (token != undefined && token.length > 0) {
        // //console.log(token);
        return true
    } else {
        return false
    }
}
//生成唯一uuid
export function generateUUID() {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
};